import { Menu, MenuItemsType } from 'components/Toolkit/Menu/Menu';
import { LogInOutlineIc, LogOutOutlineIc } from '@dsch/dd-icons';
import { theme } from 'helpers/Theme';
import {
  getHelpItem,
  getMenuItems,
} from 'components/Layouts/components/UserMenuItems.helper';

export enum USER_MENU {
  MY_DONEDEAL = 'mydonedeal',
  MY_ADS = 'myads',
  MESSAGES = 'messages',
  SAVED = 'saved',
  SEARCHES = 'searches',
  HISTORY_CHECKS = 'history-checks',
  PAYMENTS = 'payments',
  USER_LOGOUT = 'log_out',
  USER_LOGIN = 'login',
  USER_SIGN_UP = 'sign_up',
  HELP = 'help',
  BROWSING_HISTORY = 'browsing-history',
}

export enum USER_MENU_DISPLAY_NAMES {
  MY_DONEDEAL = 'Profile',
  MY_ADS = 'My Ads',
  MESSAGES = 'Messages',
  SAVED = 'Saved Ads',
  SEARCHES = 'Saved Searches',
  HISTORY_CHECKS = 'History Checks',
  PAYMENTS = 'Payment History',
  BROWSING_HISTORY = 'Browsing History',
}

export type UserMenuProps = {
  messages?: number;
  historyChecks?: number;
  user?: {
    isUserLogged: boolean;
    onUserLogOut: () => void;
    onUserLogIn: () => void;
  };
  help?: boolean;
  variant?: 'DASHBOARD';
  selectedSection?: USER_MENU;
  showMore?: boolean;
};

const UserMenu = (props: UserMenuProps) => {
  const {
    messages,
    historyChecks,
    user,
    help = false,
    variant,
    selectedSection,
    showMore,
  } = props;

  const menuItems: MenuItemsType = getMenuItems({
    selectedSection,
    historyChecks,
    messages,
    showMore,
  });

  user &&
    user.isUserLogged &&
    user.onUserLogOut &&
    menuItems.push({
      id: USER_MENU.USER_LOGOUT,
      displayName: 'Log out',
      onClick: user.onUserLogOut,
      icon: <LogOutOutlineIc color={theme.colors.GREY_DARKER} />,
    });

  user &&
    !user.isUserLogged &&
    user.onUserLogIn &&
    menuItems.push({
      id: USER_MENU.USER_LOGIN,
      displayName: 'Log in',
      onClick: user.onUserLogIn,
      icon: <LogInOutlineIc color={theme.colors.GREY_DARKER} />,
    });

  help && menuItems.push(getHelpItem());

  return (
    <Menu
      items={menuItems}
      icons
      variant={variant}
      selectedSection={selectedSection}
    />
  );
};

export { UserMenu };
