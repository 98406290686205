/**
 * <button> and <a> can both be styled to be a button.
 * These shared styles ensure that we only need to make
 * a change in one place along with types.
 */
import { css, DefaultTheme } from 'styled-components';
import type {
  ButtonHTMLAttributes,
  AnchorHTMLAttributes,
  ReactNode,
} from 'react';

import { media } from 'helpers/breakpoints';
import type { NextLinkProps } from 'types';

export type ButtonSize = 'SMALL' | 'LARGE' | 'GROW';
export type ButtonType = 'PRIMARY' | 'SECONDARY' | 'TERTIARY';

export interface CommonProps {
  size?: ButtonSize;
  ofType?: ButtonType | 'GHOST';
  inline?: boolean;
}

export interface ButtonProps
  extends CommonProps,
    ButtonHTMLAttributes<HTMLButtonElement> {
  isFakeButton?: boolean;
  icon?: ReactNode;
  hasIconAndText?: boolean;
}

export interface LinkButtonProps
  extends CommonProps,
    AnchorHTMLAttributes<HTMLAnchorElement> {
  isFakeLink?: boolean;
  NextLink?: NextLinkProps;
  disabled?: boolean;
  icon?: ReactNode;
  hasIconAndText?: boolean;
}

export function getColour(
  theme: DefaultTheme,
  ofType: CommonProps['ofType'],
  state?: 'DISABLED' | 'ACTIVE',
): string {
  switch (ofType) {
    case 'SECONDARY':
      switch (state) {
        case 'ACTIVE':
          return theme.colors.BLUE_DARK;
        case 'DISABLED':
          return theme.colors.GREY;
        default:
          return theme.colors.GREY_DARKER;
      }
    case 'GHOST':
      switch (state) {
        case 'ACTIVE':
          return theme.colors.GREY_DARK;
        case 'DISABLED':
          return theme.colors.GREY_LIGHT;
        default:
          return theme.colors.GREY_DARKER;
      }
    default:
      switch (state) {
        case 'DISABLED':
          return theme.colors.GREY;
        default:
          return theme.colors.WHITE;
      }
  }
}

export function getBackgroundColor(
  theme: DefaultTheme,
  ofType: CommonProps['ofType'],
  state?: 'DISABLED' | 'HOVER',
): string {
  switch (ofType) {
    case 'SECONDARY':
      switch (state) {
        case 'DISABLED':
          return theme.colors.GREY_LIGHT;
        case 'HOVER':
          return theme.colors.GREY_LIGHTER;
        default:
          return theme.colors.WHITE;
      }
    case 'TERTIARY':
      switch (state) {
        case 'DISABLED':
          return theme.colors.GREY_LIGHT;
        case 'HOVER':
          return theme.colors.GREY_DARK;
        default:
          return theme.colors.GREY_DARKER;
      }
    case 'GHOST':
      switch (state) {
        case 'DISABLED':
          return 'transparent';
        case 'HOVER':
          return theme.colors.GREY_LIGHTER;
        default:
          return 'transparent';
      }
    default:
      switch (state) {
        case 'DISABLED':
          return theme.colors.GREY_LIGHT;
        case 'HOVER':
          return theme.colors.BLUE_DARK;
        default:
          return theme.colors.BLUE;
      }
  }
}

export function getBorderStyles(
  theme: DefaultTheme,
  ofType: CommonProps['ofType'],
  state?: 'HOVER' | 'ACTIVE' | 'FOCUS',
): string {
  switch (ofType) {
    case 'SECONDARY':
      switch (state) {
        case 'HOVER':
          return `
          border: 1px solid ${theme.colors.GREY_DARKER};
        `;
        case 'ACTIVE':
          return `
          border: 1px solid ${theme.colors.BLUE_DARK};
        `;
        case 'FOCUS':
          return `
          border: 1px solid ${theme.colors.WHITE};
        `;
        default:
          return `  
          border: 1px solid ${theme.colors.GREY_DARKER};
        `;
      }
    case 'GHOST':
      switch (state) {
        case 'FOCUS':
          return 'border: none;';
        default:
          return '';
      }
    default:
      switch (state) {
        case 'FOCUS':
          return `
          border: 1px solid ${theme.colors.WHITE};
        `;
        default:
          return 'border: 1px solid transparent;';
      }
  }
}

function getLineHeight(
  size: CommonProps['size'],
  ofType: CommonProps['ofType'],
  isMediaLarge?: boolean,
) {
  if ((size === 'SMALL' || size === 'GROW') && ofType === 'SECONDARY') {
    return isMediaLarge ? '48px' : '40px';
  }

  if (size === 'LARGE' && ofType === 'SECONDARY') {
    return '48px';
  }

  return size === 'SMALL' || (size === 'GROW' && isMediaLarge !== true)
    ? '40px'
    : '48px';
}

export const BaseButtonCSS = css<CommonProps>`
  ${({ theme }) => theme.fontSize.M16};
  line-height: ${({ size, ofType }) => getLineHeight(size, ofType)};
  ${({ theme, ofType }) => getBorderStyles(theme, ofType)};
  background-color: ${({ theme, ofType }) => getBackgroundColor(theme, ofType)};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme, ofType }) => getColour(theme, ofType)};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  height: ${({ size }) =>
    size === 'SMALL' || size === 'GROW' ? '40px' : '48px'};
  text-align: center;
  cursor: pointer;
  position: relative;

  &:hover {
    ${({ theme, ofType }) => getBorderStyles(theme, ofType, 'HOVER')};
    background-color: ${({ theme, ofType }) =>
      getBackgroundColor(theme, ofType, 'HOVER')};
  }

  &:active {
    background-image: none;
    color: ${({ theme, ofType }) => getColour(theme, ofType, 'ACTIVE')};
    ${({ theme, ofType }) => getBorderStyles(theme, ofType, 'ACTIVE')};
  }
`;

export const DisabledCSS = css<CommonProps>`
  background-color: ${({ theme, ofType }) =>
    getBackgroundColor(theme, ofType, 'DISABLED')};
  background-image: none;
  box-shadow: none;
  border: none;
  cursor: not-allowed;
  color: ${({ theme, ofType }) => getColour(theme, ofType, 'DISABLED')};
`;

export const FocusCSS = css<CommonProps>`
  border-width: 2px;
  border-style: solid;
  outline: 2px solid ${({ theme }) => theme.colors.BLUE};
  outline-offset: 0;
  ${({ theme, ofType }) => getBorderStyles(theme, ofType, 'FOCUS')};
`;

export const ButtonCSS = css<CommonProps & { hasIconAndText: boolean }>`
  ${BaseButtonCSS};

  ${({ theme, inline }) =>
    inline
      ? css`
          display: inline-block;
          padding: 0 ${theme.spacing.S12};
        `
      : css`
          display: block;
          width: 100%;
          padding: 0;
        `}

  ${media.large} {
    ${({ size, ofType }) =>
      size === 'GROW'
        ? `
    line-height: ${getLineHeight(size, ofType, true)};
    height: 48px;
    `
        : ''};
  }

  &:focus:not(.focus-visible) {
    outline: none;
  }

  &.focus-visible {
    ${FocusCSS}
  }

  &:disabled,
  &.is-disabled {
    ${DisabledCSS};
  }

  & > svg {
    margin-top: -3px;
    margin-right: ${({ theme, hasIconAndText }) =>
      hasIconAndText ? theme.spacing.S8 : '0'};
  }
`;
