import { css } from 'styled-components';
import type { STagProps } from 'components/Toolkit/Tags/Tag';

export const BaseTagStyles = css<STagProps>`
  ${({ theme, variant }) =>
    variant === 'SMALL' ? theme.fontSize.B10 : theme.fontSize.B12};
  padding: ${({ theme, variant }) =>
    variant === 'SMALL' ? `0 ${theme.spacing.S4}` : theme.spacing.S4};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  height: ${({ variant }) => (variant === 'SMALL' ? '16px' : '24px')};
  background: ${({ theme }) => theme.colors.GREY_LIGHTER};
  display: inline-flex;
  align-items: center;
`;
