import styled from 'styled-components';

import { ButtonCSS } from 'components/Toolkit/Button/SharedButton';

import type { LinkButtonProps } from 'components/Toolkit/Button/SharedButton';

const SLinkButton = styled.a<LinkButtonProps>`
  ${ButtonCSS}
`;
/**
 * Anchor tags that look like our buttons.
 */
function ButtonLink({
  size = 'SMALL',
  ofType = 'PRIMARY',
  href,
  isFakeLink,
  NextLink,
  icon,
  children,
  disabled = false,
  onClick,
  className,
  ...rest
}: LinkButtonProps) {
  return (
    <>
      {NextLink ? (
        <NextLink href={href as string} passHref legacyBehavior>
          <SLinkButton
            size={size}
            ofType={ofType}
            as="a"
            disabled={disabled}
            className={`${className ?? ''} ${disabled && 'is-disabled'}`}
            onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
              if (disabled) {
                event.preventDefault();
              } else {
                onClick && onClick(event);
              }
            }}
            hasIconAndText={Boolean(
              icon !== undefined && children !== undefined,
            )}
            {...rest}
          >
            {icon}
            {children}
          </SLinkButton>
        </NextLink>
      ) : (
        <SLinkButton
          size={size}
          ofType={ofType}
          href={href}
          as={isFakeLink ? 'button' : 'a'}
          disabled={disabled}
          className={`${className ?? ''} ${disabled ? 'is-disabled' : ''}`}
          onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
            if (disabled) {
              event.preventDefault();
            } else {
              onClick && onClick(event);
            }
          }}
          hasIconAndText={Boolean(icon !== undefined && children !== undefined)}
          {...rest}
        >
          {icon}
          {children}
        </SLinkButton>
      )}
    </>
  );
}

export { ButtonLink };
