import styled from 'styled-components';

import { LinkCSS } from 'components/Toolkit/Button/SharedLink';

import type { LinkButtonProps } from 'components/Toolkit/Button/SharedLink';

const SLinkButton = styled.button<LinkButtonProps>`
  ${LinkCSS}
`;

/**
 * These are buttons that look like normal links.
 * SECONDARY looks like an inline link
 */
function LinkButton({
  type = 'button',
  ofType = 'PRIMARY',
  colorVariant,
  underline,
  disabled,
  children,
  ...rest
}: LinkButtonProps) {
  return (
    <SLinkButton
      ofType={ofType}
      type={type}
      colorVariant={colorVariant}
      underline={underline}
      disabled={disabled}
      aria-disabled={disabled}
      {...rest}
    >
      {children}
    </SLinkButton>
  );
}

export { LinkButton };
