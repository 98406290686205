import React from 'react';
import styled from 'styled-components';

export interface BaseBadgeProps {
  /** Number to be displayed in BaseBadge */
  count?: number;
  className?: string;
}

const Badge = styled.div<{ count?: string }>`
  color: ${({ theme }) => theme.colors.WHITE};
  background-color: ${({ theme }) => theme.colors.RED};
  ${({ theme }) => theme.fontSize.S10};
  height: 16px;
  width: ${({ count }) =>
    count && count.length > 1 ? `calc(16px + ${count.length * 4}px)` : '16px'};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  ::before,
  ::after {
    box-sizing: border-box;
  }
`;

function BaseBadge({ count, className }: BaseBadgeProps) {
  return (
    <Badge
      className={className}
      count={count !== undefined ? `${count}` : count}
    >
      {count}
    </Badge>
  );
}

export { BaseBadge };
