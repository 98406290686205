import type { ReactNode } from 'react';
import styled from 'styled-components';
import type { ThemeTypes } from 'types/Theme.namespace';

import { BaseTagStyles } from 'components/Toolkit/Tags/styles/baseTagStyles';
import { withOpacity } from 'helpers/Theme/Theme';

export interface TagProps {
  text: string;
  icon?: ReactNode;
  iconPosition?: 'LEFT' | 'RIGHT';
  variant?: 'SMALL' | 'DEFAULT';
  textColor?: ThemeTypes.Colors;
  bgColor?: ThemeTypes.Colors;
  opaque?: boolean;
  className?: string;
  as?: 'div' | 'li';
}

export interface STagProps {
  variant?: 'SMALL' | 'DEFAULT';
  color: ThemeTypes.Colors;
  bgColor: ThemeTypes.Colors;
  opaque: boolean;
}

export const STag = styled.div<STagProps>`
  direction: ltr;
  ${BaseTagStyles};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  ${({ theme, color, bgColor, opaque }) => `
    background: ${
      opaque ? withOpacity(theme.colors[bgColor]) : theme.colors[bgColor]
    };
    color: ${theme.colors[color]};
  `}

  svg {
    width: ${({ variant }) => (variant === 'SMALL' ? '12px' : '16px')};
    height: ${({ variant }) => (variant === 'SMALL' ? '12px' : '16px')};
  }
`;

const Icon = styled.div<{
  position: 'LEFT' | 'RIGHT';
  variant: 'SMALL' | 'DEFAULT';
}>`
  display: flex;
  align-items: center;
  ${({ theme, position, variant }) =>
    position === 'LEFT'
      ? `margin-right: ${
          variant === 'SMALL' ? theme.spacing.S4 : theme.spacing.S2
        }`
      : `margin-left: ${
          variant === 'SMALL' ? theme.spacing.S4 : theme.spacing.S2
        }`}
`;

function Tag({
  text,
  icon,
  iconPosition = 'RIGHT',
  variant = 'DEFAULT',
  textColor = 'WHITE',
  bgColor = 'GREY_LIGHTER',
  opaque = false,
  className,
  as = 'div',
}: TagProps) {
  return (
    <STag
      color={textColor}
      variant={variant}
      bgColor={bgColor}
      opaque={opaque}
      className={className}
      as={as}
    >
      {icon && iconPosition === 'LEFT' && (
        <Icon position={iconPosition} variant={variant}>
          {icon}
        </Icon>
      )}
      {text}
      {icon && iconPosition === 'RIGHT' && (
        <Icon position={iconPosition} variant={variant}>
          {icon}
        </Icon>
      )}
    </STag>
  );
}

export { Tag };
