import styled from 'styled-components';
import { Variants } from 'components/Toolkit/InfoTitle/InfoTitle';

interface ITitle {
  shouldTruncate: boolean;
  variant: Variants;
  flip: boolean;
  disabled?: boolean;
}

const Title = styled.p<ITitle>`
  ${({ theme, variant }) =>
    variant === 'XS'
      ? theme.fontSize.M14
      : variant === 'SMALL'
      ? theme.fontSize.M16
      : theme.fontSize.L18};

  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.GREY_DARK : theme.colors.GREY_DARKER};

  font-weight: ${({ theme }) => theme.fontWeight.bold};

  ${({ shouldTruncate }) =>
    shouldTruncate &&
    `white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;`};

  ${({ flip }) => (flip ? 'order: 2;' : '')};
`;

export { Title };
